import { createTheme, makeStyles } from "@material-ui/core";

export const useGlobalStyles = makeStyles({
  "@global": {
    "*": { boxSizing: "border-box" },
    html: {
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    body: {
      margin: "0",
      fontFamily: "'Ubuntu', serif",
      '-webkit-font-smoothing': "antialiased",
      '-moz-osx-font-smoothing': "grayscale",
      overscrollBehavior: "contain",
    },
    "#root, #clients-root": {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      width: "100vw",
      overflowY: 'scroll',
    },
    main: { position: "relative", flex: 1 },
    "a, a:hover, a:focus, a:active": {
      textDecoration: 'none',
      color: 'inherit',
    },
  }
});
export const useGlobalDashboardStyles = makeStyles({
  "@global": {
    "*": { boxSizing: "border-box" },
    html: {
      scrollbarWidth: 'thin',
      scrollbarColor: '#CAE1ED #ebf3f7',
      '&::-webkit-scrollbar': {
        width: 7,
        backgroundColor: '#ebf3f7'
      },
      '&::-webkit-scrollbar-thumb': {
        width: 7,
        backgroundColor: '#CAE1ED',
        borderRadius: '8px'
      }
    },
    body: {
      margin: "0",
      fontFamily: "'Ubuntu', serif",
      '-webkit-font-smoothing': "antialiased",
      '-moz-osx-font-smoothing': "grayscale",
      overscrollBehavior: "contain",
    },
    main: { position: "relative", flex: 1 }
  }
});
export const b2cTheme = createTheme({
  palette: {
    background: {
      default: '#EBF3F7',
    },
    primary: {
      main: '#2475FB',
    },
    text: {
      secondary: '#74737B',
      primary: '#000',
    }
  },
  typography: {
    fontFamily: ['Ubuntu'].join(','),

    body1: {
      color: '#74737B',
      fontSize: 14,
      lineHeight: '20px'
    },
    h1: {
      fontSize: '22px',
      lineHeight: '24px',
      fontWeight: 500,
      color: '#000',
    },
    h2: {
      fontSize: '20px',
      lineHeight: '22px',
      fontWeight: 500,
      color: '#000',
    },
    h4: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px"
    },
    h5: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "20px"
    },
    h6: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#383838"
    },
    subtitle1: {
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "20px",
      color: '#000',
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        padding: 0,
      }
    },
    MuiButton: {
      contained: {
        position: 'relative',
        width: '100%',
        backgroundColor: '#2475FB',
        boxShadow: '0px 12px 22px rgba(136, 17, 136, 0.05)',
        borderRadius: 8,
        padding: 0,

        fontSize: '22px',
        fontWeight: 500,
        lineHeight: '24px',
        fontStyle: 'normal',
        color: '#fff',
        textTransform: 'none',
        paddingTop: 12,
        paddingBottom: 12,

        '&:hover': {
          boxShadow: '0px 12px 22px rgba(136, 17, 136, 0.05)',
          backgroundColor: '#1c59bd'
        },
        '&:active': {
          boxShadow: '0px 12px 22px rgba(136, 17, 136, 0.05)',
        },
        '&:disabled': {
          color: '#fff',
          opacity: 0.5,
          boxShadow: 'none',
          backgroundColor: '#2475FB'
        },
        '& .MuiCircularProgress-root': {
          position: 'absolute',
          left: 15
        },
      },
      outlined: {
        position: 'relative',
        width: '100%',
        backgroundColor: '#EBF3F7',
        boxShadow: 'none',
        border: 'none',
        borderRadius: 8,
        padding: 0,

        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#d6e0e3'
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:disabled': {
          color: '#fff',
          opacity: 0.5,
          border: 'none',
        },
        '& .MuiCircularProgress-root': {
          position: 'absolute',
          left: 15
        },

        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '20px',
        fontStyle: 'normal',
        color: '#2475FB',
        textTransform: 'none',
        paddingTop: 12,
        paddingBottom: 12,
      },
      text: {
        position: 'relative',
        borderRadius: 8,

        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "20px",
        color: "#2475FB",
        textAlign: 'left',
        textTransform: 'none',
      },
      textSizeSmall: {
        fontSize: "14px",
        lineHeight: "20px"
      },
      startIcon: {
        margin: 0,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: "#fff",
      },
      colorSecondary: {
        color: '#2475FB'
      }
    },
    MuiCard: {
      root: {
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: 'none'
      }
    },
    MuiCardContent: {
      root: {
        padding: '10px',

        '&:last-child': {
          paddingBottom: 10,
        }
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          display: 'none'
        },
        '&::after': {
          display: 'none'
        }
      },
      formControl: {
        'label + &': {
          marginTop: 22
        }
      }
    },
    MuiInputBase: {
      input: {
        border: 'none',
        borderRadius: 8,
        padding: '12px 11px',
        height: 46,
        boxSizing: 'border-box',
        backgroundColor: '#EBF3F7',
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 500,
        color: '#383838',
      },
      inputMultiline: {
        padding: '12px 11px'
      },
      adornedEnd: {
        backgroundColor: '#EBF3F7',
        borderRadius: 8,
      }
    },
    MuiInputLabel: {
      formControl: {
        fontFamily: 'Ubuntu',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        color: '#383838',
        marginLeft: 5,
        transform: 'scale(1)'
      },
      shrink: {
        transform: "scale(1)"
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'Ubuntu',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '20px',
        color: '#383838',
        marginLeft: 5,
      },
      asterisk: {
        display: 'none'
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: '12px',
      }
    },
    MuiCheckbox: {
      root: {
        marginRight: '-6px',
      },

      colorSecondary: {
        '&&&': {
          color: '#2475FB',
        },
        '&&&:hover': {
          backgroundColor: 'rgba(36,117,251,0.07)',
        }
      }
    },
    MuiFormControlLabel: {
      root: {
        '&.Mui-disabled': {
          opacity: 0.4
        }
      }
    },

    // disable accordion animation
    MuiCollapse: {
      root: {
        transitionDuration: '0s!important',
      }
    },
    MuiAccordion: {
      root: {
        transitionDuration: '0s!important',
      }
    },
    MuiAccordionSummary: {
      root: {
        transitionDuration: '0s!important',
      },
      content: {
        transitionDuration: '0s!important',
      }
    },

    MuiTabs: {
      root: {
        borderBottom: 'none',
        minHeight: 38,
      },
      indicator: {
        backgroundColor: '#2475FB',
        height: '4px',
        borderRadius: '3px 3px 0px 0px'
      },
    },
    MuiTab: {
      textColorInherit: {
        fontSize: '16px',
        textTransform: 'none',
        minWidth: '0',
        maxWidth: '1000px',
        minHeight: '38px',
        padding: '8px',
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    }
  }
});