import { makeStyles } from "@material-ui/core";
import logo from "../../../icons/b2c-logo.svg";
import React from "react";
import { HeaderProfile } from "./HeaderProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 65,
    background: "#fff",
    zIndex: 1300,

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backgroundRoot: {
    height: 65,
    left: 0,
    right: 0,
    top: 0,
    background: "#fff",
    position: "fixed",
  },
  container: {
    margin: "auto",
    width: "100%",
    maxWidth: "680px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: "20%",
    "@media screen and (max-width:1060px)": {
      width: "auto",
    },
    "@media screen and (max-width:680px)": {
      paddingLeft: "12px",
    },
  },
}));

export const ClientHeader = () => {
  const classes = useStyles();

  return (
    <div className={'jss3'}>
      <div className={'jss5'}>
        <div className={'jss6'}>
          <a href={`/`}>
            <img src={logo} alt="b2c" />
          </a>
        </div>
        <HeaderProfile />
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      
.jss3 {
  width: 100%;
  height: 65px;
  display: flex;
  z-index: 1300;
  background: #fff;
  align-items: center;
  justify-content: space-between;
}
.jss4 {
  top: 0;
  left: 0;
  right: 0;
  height: 65px;
  position: fixed;
  background: #fff;
}
.jss5 {
  width: 100%;
  margin: auto;
  display: flex;
  max-width: 680px;
  align-items: center;
  justify-content: space-between;
}
.jss6 {
  width: 20%;
}
@media screen and (max-width:1060px) {
  .jss6 {
    width: auto;
  }
}
@media screen and (max-width:680px) {
  .jss6 {
    padding-left: 12px;
  }
}
      `,
        }}
      />
    </div>
  );
};
