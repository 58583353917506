import React from 'react';
import {Button, makeStyles, Typography} from '@material-ui/core';
import {useAppSelector} from "../../store";
import {selectUserProfile} from "../../store/auth/authStore";
import {ImageFile} from "../../../entities/ImageFile";
import {Link} from "react-router-dom";
import {clientRouterPrefix} from "../../../config/config";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      background: 'none',
      padding: 0,
      position: 'relative',
      minWidth: 0,
      alignItems: 'center',

      '& > *:not(:first-child)': {
        marginLeft: 16,
      },
    },
    media: {
      width: 45,
      height: 45,
    },
    avatar: {
      position: 'relative',
    },
    nameWrap: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      '& span': {
        fontWeight: 500,
        fontSize: '22px',
        lineHeight: '24px',
        textAlign: 'center',
        color: '#FFFFFF',
      },
    },
    noAvatar: {
      minWidth: 45,
      width: 45,
      height: 45,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      boxShadow: '0px 12px 22px rgba(136, 17, 136, 0.05)',
      borderRadius: 45,
    },
    content: {
      minWidth: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: 0,
      overflow: 'hidden',
      marginLeft: 10,
    },
    menuItem: {
      padding: '8px 16px',
      background: '#fff',

      '&:focus, &:hover': {
        background: '#EBF3F7',
      },
      '& > *:not(:first-child)': {
        marginLeft: 16,
      },
    },
    menuIcon: {
      width: 15,
      height: 15,
    },
  };
});

const thumbnailFirst = (imageFile?: ImageFile) => {
  return imageFile?.thumbnail || imageFile?.image;
};

const Avatar = ({ avatar }: { avatar?: ImageFile }) => {
  const classes = useStyles();

  return (
    <div
      style={{
        backgroundImage: thumbnailFirst(avatar)
          ? `url(${thumbnailFirst(avatar)})`
          : 'linear-gradient(45deg, #00B3FF 0%, #6A11CB 100%)',
      }}
      className={classes.noAvatar}
    />
  );
};

export const HeaderProfile = () => {
  const clientProfile = useAppSelector(selectUserProfile);
  const classes = useStyles();
  const {t} = useTranslation();

  if (!clientProfile) {
    return (
      <Button variant={'text'} type={'a'} href={'/'}>
        {t('back-to-homepage')}
      </Button>
    );
  }

  return (
    <Link to={{pathname: `${clientRouterPrefix}/me`}}>
      <div className={classes.root}>
        <Typography variant={'h5'} color={'primary'} style={{ textAlign: 'right' }}>
          {`${clientProfile?.firstName} ${clientProfile?.lastName}`}
        </Typography>
        <div className={classes.avatar}>
          <Avatar avatar={clientProfile.avatar} />
          {!thumbnailFirst(clientProfile.avatar) && (
            <div className={classes.nameWrap}>
            <span>
              {`${clientProfile.firstName} `[0].toUpperCase()}
              {`${clientProfile.lastName} `[0]?.toUpperCase()}
            </span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
