import React from 'react';
import {makeStyles, TextField} from "@material-ui/core";
import {FormEvent, KeyboardEvent, RefObject, useCallback, useRef} from "react";

const useCodeInputStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',

    '& > *': {
      marginLeft: 12
    },
    '& > *:first-child': {
      marginLeft: 0
    }
  },
  input: {
    width: 70,
    height: 70,
    textAlign: 'center'
  }
});

const nextPrevInputElements = (index: number, listRef: RefObject<HTMLDivElement>) => {
  if (!listRef.current) {
    throw new Error(
      "listRef.current is empty. " +
      "Make sure to call this function after ref is initialized!"
    );
  }

  const list = listRef.current;
  const nextInputDiv = list.childNodes.item(index + 1) as HTMLInputElement;
  const prevInputDiv = list.childNodes.item(index - 1) as HTMLInputElement;

  return [prevInputDiv?.querySelector('input'), nextInputDiv?.querySelector('input')];
};

type SmsCodeInputProps = {
  defaultCode?: string,
  length: number,
  onCodeChange: (code: string) => void
};

export const SmsCodeInput = ({defaultCode, onCodeChange, length}: SmsCodeInputProps) => {
  const classes = useCodeInputStyles();
  const listRef = useRef<HTMLDivElement>(null);

  const collectCode = useCallback(() => {
    if (!listRef.current) {
      return;
    }

    const code = [].map.call(listRef.current.childNodes, (node) => {
      const inputDiv = node as HTMLInputElement;
      const input = inputDiv.querySelector('input');
      return input?.value;
    }).join('');

    onCodeChange(code);
  }, [listRef, onCodeChange]);

  const onKeyPress = useCallback((
    index: number,
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const [, nextInput] = nextPrevInputElements(index, listRef);
    const input = event.target as HTMLInputElement;
    const number = parseInt(event.key);

    event.preventDefault();

    if (number >= 0) {
      input.value = event.key;
      if (nextInput) nextInput.focus();
      collectCode();
    }
  }, [listRef, collectCode]);

  const onKeyDown = useCallback((
    index: number,
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  )  => {
    const [prevInput, nextInput] = nextPrevInputElements(index, listRef);
    const input = event.target as HTMLInputElement;

    if (event.key === 'ArrowLeft') {
      if (prevInput) prevInput.focus();
    } else if (event.key === 'ArrowRight') {
      if (nextInput) nextInput.focus();
    } else if (event.key === 'Backspace') {
      if (input.value) {
        input.value = '';
      } else if (prevInput) {
        prevInput.value = '';
        prevInput.focus();
      }

      event.preventDefault();
      collectCode();
    }
  }, [listRef, collectCode]);

  const pasteRest = useCallback((idx: number, current: HTMLInputElement, chars: string) => {
    const [, nextInput] = nextPrevInputElements(idx, listRef);

    current.value = chars[0];
    current.focus();

    if (!nextInput || chars.length <= 0) {
      return;
    }

    pasteRest(idx + 1, nextInput, chars.substring(1));
  }, []);

  const onInputChange = useCallback((
    index: number,
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value.length > 1) {
      pasteRest(index, input, value);
      collectCode();
    }
  }, [collectCode, pasteRest]);

  return (
    <div ref={listRef} className={classes.container}>
      {Array(length).fill(0).map((_item, i) => {
        return (
          <TextField
            key={'tf_' + i}
            classes={{root: classes.input}}
            inputProps={{
              className: classes.input,
              autoFocus: i === 0,
              pattern: "[0-9]*",
              type: "text",
              inputMode: 'numeric',
              onChange: (e) => onInputChange(i, e),
              onKeyPress: (e) => onKeyPress(i, e),
              onKeyDown: (e) => onKeyDown(i, e)
            }}
          />
        );
      })}
    </div>
  );
};