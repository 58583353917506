import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrlV1 } from "../../../config/config";
import { agent, encodeGetParams } from "../../../api";
import { Messages } from "src/entities/Message";

export const loadChatRooms = createAsyncThunk(
  'chat/load',
  async (param: { search: string }) => {
    const encodedGetParams = encodeGetParams({
      search: param.search
    })
    const res = await agent.get(`${apiUrlV1}/chat/rooms/${encodedGetParams}`);

    if (res.status === 200) {
      const body = await res.json();
      return body as any;
    }

    throw Error("Couldn't load user profile");
  }
)

export const createChatRoom = createAsyncThunk(
  'chat/create',
  async (params: number) => {
    const res = await agent.post(`${apiUrlV1}/chat/create-or-get-room/`, {
      body: JSON.stringify({ second_user: params })
    })

    if (res.status === 200) {
      const body = await res.json();
      return body as any;
    }
  })
export const connectionHistoryChat = createAsyncThunk(
  'chat/connection/history',
  async (user_id: number) => {
    const res = await agent.get(`${apiUrlV1}/chat/connection-history/${user_id}/`)

    if (res.status === 200) {
      const body = await res.json();
      return body as any;
    }

  })

export const loadMessageByRoom = createAsyncThunk(
  'chat/messages',
  async (params: { room_id: number, date?: string }) => {
    let encoded: any
    if (params.date) {
      encoded = {
        'room_id': params.room_id,
        'datetime__lt': params.date
      }
    } else {
      encoded = { 'room_id': params.room_id }
    }
    const encodedGetParams = encodeGetParams(encoded)
    const res = await agent.get(`${apiUrlV1}/chat/messages/${encodedGetParams}`)

    if (res.status === 200) {
      const body = await res.json();
      return body as any;
    }
  }
)
export const loadPrevMessageByRoom = createAsyncThunk(
  'chat/prev/messages',
  async (params: { room_id: number, date: string }) => {
    let encoded = encodeGetParams({
      'room_id': params.room_id,
      'datetime__lt': params.date
    })
    const res = await agent.get(`${apiUrlV1}/chat/messages/${encoded}`)

    if (res.status === 200) {
      const body = await res.json();
      return body as any;
    }
  }
)