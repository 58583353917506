import {SmsCodeVerifyCard} from "./SmsCodeVerifyCard";
import React, {useCallback, useState} from "react";
import {PhoneEnterCard} from "./PhoneEnterCard";
import {ProfileEnterCard} from "./ProfileEnterCard";
import {apiUrlV1} from "../../../config/config";
import UserProfile, {Role} from "../../../entities/UserProfile";
import {setRefreshToken} from "../../../api";
import {usePageTitle} from "../../../navigation/usePageTitle";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core";
import {ReactComponent as LogoIcon} from '../../../icons/logo.svg';

const useStyles = makeStyles({
  clientAuthWizard: {
    height: 'calc(100vh - 65px)',
    background: "linear-gradient(45deg, #00B3FF 0%, #6A11CB 100%)",
  },
  logoWrap: {
    position: "absolute",
    top: "calc(100vh - 70px - 32px)",
    margin: "auto",
    left: "0",
    right: "0",
    width: "fit-content",

    '& > *': {
      width: 118,
      height: 70,
    },
  },
});

type AuthWizardProps = {
  onUserAuth: (token: string, profile: UserProfile) => void,
  role: Role,
};

export const AuthWizard = ({onUserAuth, role}: AuthWizardProps) => {
  const [step, stepSet] = useState<'phone' | 'sms' | 'profile'>('phone');
  const classes = useStyles();
  const [token, tokenSet] = useState<string>();
  const [phoneNumber, phoneNumberSet] = useState('');
  const {t} = useTranslation();

  usePageTitle(t('auth'));

  const onPhoneEnter = useCallback((phone) => {
    phoneNumberSet(phone);
    stepSet('sms');
  }, [phoneNumberSet, stepSet]);

  const getUserProfile = useCallback((token: string) => {
    return fetch(`${apiUrlV1}/profiles/me/`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${token}`
      }
    });
  }, []);

  const onCodeValidEnter = useCallback((token: string, refreshToken: string) => {
    getUserProfile(token).then(res => {
      tokenSet(token);
      setRefreshToken(refreshToken);
      // 403 user is created but registration is not completed
      if (res.status === 403) {
        stepSet('profile');
      }
      // user is created and registration completed
      else if (res.status === 200) {
        res.json().then(user => {
          const profile: UserProfile = user as UserProfile;
          onUserAuth(token, profile);
        });
      }
      // real shit happens here
      else {
        throw Error('Unable to get user\' profile');
      }
    });
  }, [tokenSet, stepSet, getUserProfile, onUserAuth]);
  
  const onProfileCreated = useCallback(() => {
    if (!token) throw Error("Token is empty!");

    getUserProfile(token).then(res => {
      if (res.status === 200) {
        res.json().then(user => {
          const profile: UserProfile = user as UserProfile;
          onUserAuth(token, profile);
        });
      }
    })
  }, [getUserProfile, token, onUserAuth]);

  return (
    <div className={clsx(
      role === 'customer' && classes.clientAuthWizard
    )}>
      {step === 'phone' && (
        <div>
          <PhoneEnterCard onPhoneEnter={onPhoneEnter}/>
        </div>
      )}
      {step === 'sms' && (
        <div>
          <SmsCodeVerifyCard
            role={role}
            phoneNumber={phoneNumber}
            onCodeValidEnter={onCodeValidEnter}
          />
        </div>
      )}
      {step === 'profile' && (
        <div>
          {token && (
            <ProfileEnterCard
              role={role}
              onProfileCreated={onProfileCreated}
              token={token}
            />
          )}
        </div>
      )}
      {role === 'customer' && (
        <div className={classes.logoWrap}>
          <LogoIcon/>
        </div>
      )}
    </div>
  );
}
