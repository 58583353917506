import {IconButton, IconButtonProps, SvgIcon, CircularProgress} from "@material-ui/core";
import {useCallback} from "react";
import {ReactComponent as GeoPositionIcon} from './geo-position.svg';
import {useTranslation} from "react-i18next";

interface GeoPositionButtonProps {
  iconButtonProps?: IconButtonProps,
  onPositionChange?: (pos: GeolocationPosition) => void,
  loading?: boolean
}

export function GeoPositionButton(props: GeoPositionButtonProps) {
  const {t} = useTranslation();
  const geolocation = window.navigator.geolocation;
  const geolocationSupported = !!geolocation;
  const {iconButtonProps, onPositionChange, loading} = props;

  const onClick = useCallback(() => {
    if (!loading) {
      onPositionChange && geolocation.getCurrentPosition((pos) => {
        onPositionChange(pos);
      });
    }
  }, [geolocation, onPositionChange, loading]);

  return (
    <IconButton
      title={t('press-to-locate')}
      onClick={onClick}
      disabled={!geolocationSupported}
      {...iconButtonProps}
    >
      {loading
        ? <CircularProgress color={'secondary'} size={24} />
        : <SvgIcon component={GeoPositionIcon} viewBox="0 0 30 30" />}
    </IconButton>
  );
}