import('webfontloader').then(WebFont => {
  WebFont.load({
    google: {
      families: [
        'Ubuntu:300,400,500&display=swap'
      ]
    },
    active: () => {
      sessionStorage.fontsLoaded = true
    },
  });
});

export {};
