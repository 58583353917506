import {createAsyncThunk} from "@reduxjs/toolkit";
import {apiUrlV1} from "../../../config/config";
import {CustomerProfile, PersonalInfo} from "../../../entities/UserProfile";
import {agent} from "../../../api";
import {fetchClientProfile} from "../auth/profileLoad";
import {mergeUserProfile} from "../auth/authStore";
import {locationToPlaceType, PlaceType, placeTypeToLocation} from "../../../entities/PlaceType";

const postLocation = (place: PlaceType) => {
  return agent.post(`${apiUrlV1}/locations/`, {
    body: JSON.stringify(placeTypeToLocation(place))
  }).then(
    res => res.json()
  ).then(body => {
    return locationToPlaceType(body);
  });
}

const fetchLocation = (locationId: number) => {
  return agent.get(`${apiUrlV1}/locations/${locationId}/`)
    .then(res => res.json())
    .then(body => {
      return locationToPlaceType(body);
    });
}

const getLocationId = (place: PlaceType) => {
  if (!place.id) {
    return postLocation(place).then(location => location.id);
  }

  return fetchLocation(place.id).then(location => location.id);
}

export const loadUserProfile = createAsyncThunk(
  'profile/load',
  async () => {
    const res = await agent.get(`${apiUrlV1}/profiles/me/`);

    if (res.status === 200) {
      const body = await res.json();
      return body as CustomerProfile;
    }

    throw Error("Couldn't load user profile");
  },
)

export const updateCustomerPersonalInfo = createAsyncThunk(
  'profile/update',
  async (personalInfo: Partial<PersonalInfo>, thunkApi) => {
    const personalInfoPart: Partial<PersonalInfo> = {...personalInfo};
    const locationId = personalInfo.locationId
      || (personalInfo.location && await getLocationId(personalInfo.location));

    delete personalInfoPart.phone;
    delete personalInfoPart.city;
    delete personalInfoPart.location;
    delete personalInfoPart.avatar;
    delete personalInfoPart.cover;

    const res = await agent.put(`${apiUrlV1}/profiles/me/customer/`, {
      body: JSON.stringify({
        ...personalInfoPart,
        avatarId: personalInfo.avatar?.id || null,
        locationId,
      }),
    });

    if (res.status === 200) {
      thunkApi.dispatch(fetchClientProfile());
      return;
    }

    throw Error("Couldn't update customer profile");
  }
);

export const patchUserPersonalInfo = createAsyncThunk(
  'profile/patch',
  async (personalInfo: Partial<PersonalInfo>, thunkApi) => {
    const personalInfoPart: Partial<PersonalInfo> = {...personalInfo};

    const locationId = personalInfoPart.locationId
      || (personalInfo.location && await getLocationId(personalInfo.location));

    delete personalInfoPart.location;

    const res = await agent.patch(`${apiUrlV1}/profiles/me/customer/`, {
      body: JSON.stringify({
        ...personalInfoPart,
        locationId,
      }),
    });

    if (res.status === 200) {
      thunkApi.dispatch(fetchClientProfile());
    }
  }
);

export const sendSms = createAsyncThunk(
  'profile/sendSms',
  async (phoneNew: string) => {
    const res = await agent.post(`${apiUrlV1}/otp/send-sms/`, {
      body: JSON.stringify({
        phone: `+${phoneNew}`,
        type: 'ph_ch',
      }),
    });

    if (res.status === 201) {
      return;
    }

    throw Error(`Couldn't send an otp :(`);
  }
);

type PhoneChangeActionPayload = {
  phone: string,
  smsCode: string
};

export const phoneChange = createAsyncThunk(
  'profile/phoneChange',
  async (data: PhoneChangeActionPayload, thunkApi) => {
    const res = await agent.post(`${apiUrlV1}/profiles/change-phone/`, {
      body: JSON.stringify({
        phone: `+${data.phone}`,
        otpCode: data.smsCode,
      })
    });

    if (res.status === 200) {
      thunkApi.dispatch(mergeUserProfile({phone: data.phone}));
      return;
    }

    throw Error('otp wrong');
  }
);
