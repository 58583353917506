import {createAsyncThunk} from "@reduxjs/toolkit";
import {apiUrlV1} from "../../../config/config";
import {CustomerProfile} from "../../../entities/UserProfile";
import {agent} from "../../../api";
import {locationToPlaceType} from "../../../entities/PlaceType";

const customerUserParse = (obj: any): CustomerProfile => {
  return {
    ...obj,
    location: obj.location && locationToPlaceType(obj.location),
  }
};

export const fetchClientProfile = createAsyncThunk(
  'auth/fetchUserProfile',
  async () => {
    const userProfileRes = await agent.get(`${apiUrlV1}/profiles/me/`);

    if (userProfileRes.status !== 200) {
      localStorage.removeItem('customerProfile');
      return null;
    }

    const profile = await userProfileRes.json();

    localStorage.setItem('customerProfile', JSON.stringify(profile));

    return customerUserParse(profile);
  }
);

export const userLogOut = createAsyncThunk(
  'auth/userLogOut',
  async (data, thunkApi) => {
    thunkApi.dispatch({type: 'logout'});
    localStorage.removeItem('token');
    localStorage.removeItem('customerProfile');
  }
);
