import React from 'react';
import {
  Autocomplete as AutocompleteRff,
  AutocompleteProps as AutocompletePropsRff
} from 'mui-rff';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {makeStyles, Typography, Grid, TextField, Theme, TextFieldProps} from '@material-ui/core';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import match from "autosuggest-highlight/match";
import {PlaceKind, PlaceType} from '../../entities/PlaceType';
import {GeocoderGetter, GeocoderParams} from "./geocoder";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams
} from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  autocompleteInputRoot: {
    '&&&&&& $input': {
      padding: '12px 11px'
    },
  },
  autocompleteEndAdornment: {
    marginRight: 9
  }
}));

type GeocoderAddressParams = {
  kind?: PlaceKind,
  geocoderParams?: GeocoderParams,
  geocoderGetter: GeocoderGetter,
  textFieldProps?: TextFieldProps,
};

function useGeocoderAddressAutocompleteProps(
  params: GeocoderAddressParams
): AutocompleteProps<PlaceType, false, any, false> {
  const classes = useStyles();
  const {t} = useTranslation();
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const {geocoderParams} = params;
  const geocoderGetter = params.geocoderGetter;

  const fetchGeocode = React.useMemo(
    () =>
      throttle(geocoderGetter(geocoderParams || {}), 200),
    [geocoderParams, geocoderGetter],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchGeocode({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchGeocode]);

  return {
    getOptionLabel: (option) => (
      typeof option === 'string'
        ? option
        : [option.name, option.description].filter(i => i).join(', ')
    ),
    filterOptions: (x) => x,
    getOptionSelected: (option, value) => {
      if (!option.pos || !value.pos) {
        return false;
      }

      return Math.abs(option.pos[0] - value.pos[0]) <= 0.001
        && Math.abs(option.pos[1] - value.pos[1]) <= 0.001;
    },
    options: value ? [value, ...options] : options,
    autoComplete: true,
    includeInputInList: true,
    filterSelectedOptions: true,
    noOptionsText: t('no-addresses'),
    // value: value,
    classes: {
      inputRoot: classes.autocompleteInputRoot,
      endAdornment: classes.autocompleteEndAdornment
    },
    onChange: (event: any, newValue: PlaceType | null, reason, details) => {
      setOptions(newValue ? [newValue, ...options] : options);
      setValue(newValue);
    },
    onInputChange: (event: any, newInputValue: string) => {
      setInputValue(newInputValue);
    },
    renderInput: (inputParams: AutocompleteRenderInputParams) => (
      <TextField {...inputParams}
                 placeholder={t('address-enter-prompt')}
                 InputLabelProps={{shrink: true}}
                 label={t('address')}
                 {...params.textFieldProps}
      />
    ),
    renderOption: (option: PlaceType) => {
      const matches = match(option.name, inputValue);
      const parts = parse(
        option.name,
        matches
      );

      return (
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            {parts.map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
            ))}
            <Typography variant="body2" color="textPrimary">
              {option.description}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };
}

type GeocoderAddressAutocompleteProps = {
  autocompleteProps: Omit<
    AutocompleteProps<PlaceType, false, any, false>,
    'options' | 'renderInput'
    >
} & GeocoderAddressParams;

type GeocoderAddressAutocompleteRffProps = {
  autocompleteProps: Omit<
    AutocompletePropsRff<PlaceType, false, any, false>,
    'options' | 'renderInput'
    >
} & Omit<GeocoderAddressParams, 'textFieldProps'>;

export function GeocoderAddressAutocomplete(props: GeocoderAddressAutocompleteProps) {
  const {autocompleteProps, ...geocoderParams} = props;
  const geocoderAddressAutocompleteProps = useGeocoderAddressAutocompleteProps(geocoderParams);

  const onChange: (
    event: React.ChangeEvent<{}>,
    value: PlaceType | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<PlaceType>
  ) => void = (event, value, reason, details) => {
    geocoderAddressAutocompleteProps.onChange
    && geocoderAddressAutocompleteProps.onChange(event, value, reason, details);
    autocompleteProps.onChange && autocompleteProps.onChange(event, value, reason, details);
  }

  return (
    <Autocomplete {...geocoderAddressAutocompleteProps} {...autocompleteProps} onChange={onChange} />
  );
}

export function GeocoderAddressAutocompleteRff(props: GeocoderAddressAutocompleteRffProps) {
  const {autocompleteProps, ...geocoderParams} = props;
  const {renderInput, ...geocoderAddressAutocompleteProps} = useGeocoderAddressAutocompleteProps(geocoderParams);

  const onChange: (
    event: React.ChangeEvent<{}>,
    value: PlaceType | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<PlaceType>
  ) => void = (event, value, reason, details) => {
    geocoderAddressAutocompleteProps.onChange
    && geocoderAddressAutocompleteProps.onChange(event, value, reason, details);
    autocompleteProps.onChange && autocompleteProps.onChange(event, value, reason, details);
  }

  return (
    <AutocompleteRff {...geocoderAddressAutocompleteProps} {...autocompleteProps} onChange={onChange} />
  );
}
