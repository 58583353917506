import {Lang} from "../entities/Lang";

export const apiUrlV1: string = process.env.REACT_APP_API_URL_V1 || '';
export const wsUrl: string = process.env.REACT_APP_WS_URL || ''
export const mapsProvider: string = process.env.REACT_APP_MAPS_API_PROVIDER || 'yandex';
export const yandexMapsApiKey: string = process.env.REACT_APP_YANDEX_MAPS_API_KEY || '';
export const googleMapsApiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
export const countryCode: string = process.env.REACT_APP_COUNTRY_CODE || 'BY';
export const mainLanguage: Lang = (process.env.REACT_APP_MAIN_LANGUAGE || 'ru') as Lang;
export const languageLocalStorageKey: string = (process.env.REACT_APP_LANGUAGE_LOCAL_STORAGE_KEY || 'language');
export const yandexBbox: string = process.env.REACT_APP_YANDEX_BBOX || '';
export const clientRouterPrefix: string = process.env.REACT_APP_CLIENT_ROUTER_PREFIX || '';
export const publicUrl: string = process.env.PUBLIC_URL || '';

