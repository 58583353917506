import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Radio, {RadioProps} from '@material-ui/core/Radio';
import RadioGroup, {RadioGroupProps, useRadioGroup} from '@material-ui/core/RadioGroup';
import FormControlLabel, {FormControlLabelProps} from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import {ErrorMessage, RadiosProps, showErrorOnChange} from 'mui-rff';
import {FormControl, FormLabel} from "@material-ui/core";
import {Field, useField} from "react-final-form";
import {RadioData} from "mui-rff/src/Radios";

const useStyles = makeStyles({
  root: {
    borderRadius: 8,
    border: 0,
    height: 46,
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    flex: 1,
    transition: 'background 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
  },
  rootChecked: {
    background: '#2475FB',
  },
  label: {
    color: '#383838',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 16,
    transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  },
  labelChecked: {
    color: '#EBF3F7',
  },
  radioGroup: {
    backgroundColor: '#EBF3F7',
    borderRadius: 8
  },
  radio: {
    visibility: 'collapse',
    padding: 0,
  }
});

export const B2CRadioGroup = (props: RadioGroupProps) => {
  const classes = useStyles();

  return (
    <RadioGroup {...props} classes={{
      root: clsx(classes.radioGroup, props.classes?.root)
    }} />
  );
};

export const B2CFormControlLabel = (props: FormControlLabelProps) => {
  const classes = useStyles();
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return (
    <FormControlLabel {...props} classes={{
      root: clsx(props.classes?.root, classes.root, checked && classes.rootChecked),
      label: clsx(props.classes?.label, classes.label, checked && classes.labelChecked),
    }}/>
  );
};

export function B2CRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      classes={{root: classes.radio}}
      color="default"
      checkedIcon={<div />}
      icon={<div />}
      {...props}
    />
  );
}

export function B2CRadios(props: RadiosProps) {
  const {
    name,
    data,
    label,
    required,
    helperText,
    formLabelProps,
    formControlLabelProps,
    fieldProps,
    formControlProps,
    radioGroupProps,
    formHelperTextProps,
    showError = showErrorOnChange,
    ...restRadios
  } = props;

  const field = useField(name);
  const isError = showError(field);

  return (
    <FormControl required={required} error={isError} {...formControlProps}>
      {!!label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
      <B2CRadioGroup row {...radioGroupProps} value={field.input.value}>
        {data.map((item: RadioData, idx: number) => (
          <B2CFormControlLabel
            key={idx}
            name={name}
            label={item.label}
            value={item.value}
            disabled={item.disabled}
            control={
              <Field
                name={name}
                type="radio"
                render={({ input: { name, value, onChange, checked, ...restInput } }) => (
                  <B2CRadio
                    name={name}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    disabled={item.disabled}
                    required={required}
                    inputProps={{ required, ...restInput }}
                    {...restRadios}
                  />
                )}
                {...fieldProps}
              />
            }
            {...formControlLabelProps}
          />
        ))}
      </B2CRadioGroup>
      <ErrorMessage
        showError={isError}
        meta={field.meta}
        formHelperTextProps={formHelperTextProps}
        helperText={helperText}
      />
    </FormControl>
  );
}
