import {fromGoogleAddressObject, fromYandexGeoObject, PlaceKind, PlaceType} from "../../entities/PlaceType";
import {
  apiUrlV1,
  countryCode,
  googleMapsApiKey,
  mainLanguage,
  mapsProvider,
  yandexBbox,
  yandexMapsApiKey
} from "../../config/config";
import {Country} from "../../entities/Country";
import {getGoogleAddressLocation, getGoogleAddressObject} from "../../entities/GoogleAddressObject";
import {TFunction} from "react-i18next";

export type GeocoderRequest = {input: string};
export type GeocoderCallback = (results?: PlaceType[]) => void;
export type Geocoder = (request: GeocoderRequest, callback: GeocoderCallback) => void;
export type GeocoderParams = {
  kind?: PlaceKind,
};
export type GeocoderGetter = (params: GeocoderParams) => Geocoder;

const getYandexGeocoder = (t: TFunction) => (params: GeocoderParams) => {
  const {kind} = params;

  return (
    request: GeocoderRequest,
    callback: GeocoderCallback
  ) => {
    if (request.input.length === 0) {
      callback([]);
      return;
    }

    fetch(
      'https://geocode-maps.yandex.ru/1.x' +
      `?geocode=${t('countries.' + countryCode)} ${request.input}` +
      `&bbox=${yandexBbox}` +
      `&apikey=${yandexMapsApiKey}` +
      `&format=${'json'}` +
      `&results=${100}`
    ).then(res => {
      return res.json();
    }).then(res => {
      const list: any[] = res?.response
        ?.GeoObjectCollection
        ?.featureMember
        .map((i: any) => i.GeoObject)
        .filter((i: any) => {
          return i.metaDataProperty.GeocoderMetaData.Address.country_code === countryCode
        })
        .filter((i: any) => {
          if (!kind) {
            return true;
          }

          return i.metaDataProperty.GeocoderMetaData.kind === kind;
        });
      const places = list.map(fromYandexGeoObject);
      callback(places);
    }).catch(e => {
      console.error(e);
    });
  }
}

const getGoogleGeocoder: GeocoderGetter = (params: GeocoderParams) => {
  // const {kind} = params;

  return (
    request: GeocoderRequest,
    callback: GeocoderCallback
  ) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json` +
      `?address=${request.input}` +
      `&region=${countryCode}` +
      `&language=${mainLanguage}` +
      `&result_type=premise|street_address` +
      `&key=${googleMapsApiKey}`
    ).then(res => {
      return res.json();
    }).then(body => {
      const places = body.results.map((r: any) => {
        return fromGoogleAddressObject(
          getGoogleAddressObject(r),
          getGoogleAddressLocation(r),
          'house'
        );
      }) as PlaceType[];

      callback(places);
    });
  };
}

export const getAddressGeocoder = (t: TFunction) => (params: GeocoderParams) => {
  if (mapsProvider === 'google') {
    return getGoogleGeocoder(params);
  }

  return getYandexGeocoder(t)(params);
}

export const getCityGeocoder: GeocoderGetter = (params: GeocoderParams) => {
  return (request, callback) => {
    fetch(
      `${apiUrlV1}/cities` +
      `?search=${encodeURIComponent(request.input)}` +
      `&limit=${15}`
    ).then(res => {
      return res.json();
    }).then(({results: cities}) => {
      const places: PlaceType[] = cities.map((item: any) => {
        return {
          id: item.id,
          name: item.title,
          description: `${item.region} область`,
          region: item.region,
          country: Country.Belarus
        } as PlaceType;
      });

      callback(places);
    });
  };
};
