import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  loadUserProfile,
  patchUserPersonalInfo,
  phoneChange,
  sendSms,
  updateCustomerPersonalInfo,
} from "./thunks";
import {RootState} from "../index";
import {CustomerProfile} from "../../../entities/UserProfile";

type PhoneChangeStep = 'view' | 'phone' | 'sms';

interface PhoneChangeState {
  phoneNew?: string,
  step: PhoneChangeStep,
  smsSending: boolean,
  smsSendError: boolean,
  smsSentTimeMilliseconds: number,
  phoneChanging: boolean,
  phoneChangeError: boolean,
  phoneChanged: boolean,
}

const initialPhoneChangeState: PhoneChangeState = {
  step: 'view',
  smsSending: false,
  smsSendError: false,
  smsSentTimeMilliseconds: new Date().getTime(),
  phoneChanging: false,
  phoneChangeError: false,
  phoneChanged: false,
};

export interface ProfileState {
  avatarState: 'pending' | 'loaded',
  loading: boolean,
  saving: boolean,
  saved: boolean,
  profile?: CustomerProfile,
  phoneChange: PhoneChangeState,
}

const initialState: ProfileState = {
  avatarState: 'loaded',
  loading: false,
  saving: false,
  saved: false,
  profile: undefined,
  phoneChange: initialPhoneChangeState
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    initPhoneChange: (state) => {
      state.phoneChange = initialPhoneChangeState;
    },
    phoneChangeStepChange: (state, action: PayloadAction<PhoneChangeStep>) => {
      state.phoneChange.step = action.payload;
    },
    phoneChangePhoneNewChange: (state, action: PayloadAction<string>) => {
      state.phoneChange.phoneNew = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadUserProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
    });

    builder.addCase(updateCustomerPersonalInfo.pending, (state) => {
      state.saving = true;
      state.saved = false;
    });
    builder.addCase(updateCustomerPersonalInfo.fulfilled, (state) => {
      state.saving = false;
      state.saved = true;
    });
    builder.addCase(updateCustomerPersonalInfo.rejected, (state) => {
      state.saving = false;
      state.saved = false;
    });

    builder.addCase(patchUserPersonalInfo.pending, (state) => {
      state.saving = true;
      state.saved = false;
    });
    builder.addCase(patchUserPersonalInfo.fulfilled, (state) => {
      state.saving = false;
      state.saved = true;
    });
    builder.addCase(patchUserPersonalInfo.rejected, (state) => {
      state.saving = false;
      state.saved = false;
    });

    builder.addCase(sendSms.pending, (state) => {
      state.phoneChange.smsSending = true;
      state.phoneChange.smsSendError = false;
      state.phoneChange.smsSentTimeMilliseconds = new Date().getTime();
    });
    builder.addCase(sendSms.fulfilled, (state) => {
      state.phoneChange.smsSending = false;
      state.phoneChange.smsSendError = false;
      state.phoneChange.step = 'sms';
    });
    builder.addCase(sendSms.rejected, (state) => {
      state.phoneChange.smsSending = false;
      state.phoneChange.smsSendError = true;
    });

    builder.addCase(phoneChange.pending, (state) => {
      state.phoneChange.phoneChanged = false;
      state.phoneChange.phoneChanging = true;
      state.phoneChange.phoneChangeError = false;
    });
    builder.addCase(phoneChange.fulfilled, (state) => {
      state.phoneChange.phoneChanging = false;
      state.phoneChange.phoneChangeError = false;
      state.phoneChange.step = 'view';
      state.phoneChange.phoneChanged = true;
    });
    builder.addCase(phoneChange.rejected, (state) => {
      state.phoneChange.phoneChanging = false;
      state.phoneChange.phoneChangeError = true;
    });
  }
});

export const profileReducer = profileSlice.reducer;

export const {initPhoneChange, phoneChangeStepChange, phoneChangePhoneNewChange} = profileSlice.actions;

export const selectProfileState = (state: RootState) => state.profile;

export const selectCustomerProfile = (state: RootState) => {
  return state.profile.profile;
};

export const selectPhoneChangeState = (state: RootState) => {
  return state.profile.phoneChange;
}
