import {CustomerProfile} from "../../../entities/UserProfile";
import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {fetchClientProfile, userLogOut} from "./profileLoad";

interface AuthState {
  token: string | null;
  profile: CustomerProfile | null;
  loading: boolean;
}

const localStorageToken: string | null = localStorage.getItem('token') || null;

const initialState: AuthState = {
  token: localStorageToken,
  profile: null,
  loading: !!localStorageToken,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // changeUserProfile: (state, action: PayloadAction<CustomerProfile>) => {
    //   state.profile = action.payload;
    //   state.loading = false;
    // },
    mergeUserProfile: (state, action: PayloadAction<Partial<CustomerProfile>>) => {
      if (state.profile) {
        state.profile = {...state.profile, ...action.payload};
      } else {
        throw Error('Nothing to merge! Be sure to merge only if profile state is not null');
      }
      state.loading = false;
    },
    // changeUserPersonalInfo: (state, action: PayloadAction<PersonalInfo>) => {
    //   if (state.profile) {
    //     state.profile.personalInfo = action.payload;
    //   }
    // },
    changeUserToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientProfile.fulfilled, (state: Draft<AuthState>, action) => {
      state.profile = action.payload;
      state.loading = false;
    });
    builder.addCase(userLogOut.fulfilled, (state: Draft<AuthState>, action) => {
      state.profile = null;
      state.loading = false;
      state.token = null;
    });
  }
});

export const {changeUserToken, mergeUserProfile} = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;
export const selectUserProfile = (state: RootState) => state.auth.profile;

export default authSlice.reducer;
