// https://medium.com/@almestaadmicadiab/how-to-parse-google-maps-address-components-geocoder-response-774d1f3375d

import {Point} from "./Point";

export interface GoogleAddressObject {
  home: string,
  postal_code: string,
  street: string,
  region: string,
  city: string,
  country: string,
  formatted_address: string,
}

export const getGoogleAddressObject = (addressObj: any) => {
  const address_components = addressObj.address_components;
  const shouldBeComponent: Record<string, any> = {
    home: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    region: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5"
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4"
    ],
    country: ["country"]
  };

  const address: any = {
    home: "",
    postal_code: "",
    street: "",
    region: "",
    city: "",
    country: "",
    formatted_address: addressObj.formatted_address,
  };

  address_components.forEach((component: any) => {
    for (const shouldBe in shouldBeComponent) {
      if (shouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });

  return address as GoogleAddressObject;
}

export const getGoogleAddressLocation = (addressObj: any) => {
  const {lat, lng} = addressObj.geometry.location;
  return [lat, lng] as Point;
}
