import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionHistory, Messages, Chats, Message } from "src/entities/Message";
import { RootState } from "../index";
import { connectionHistoryChat, createChatRoom, loadChatRooms, loadMessageByRoom, loadPrevMessageByRoom } from "./thunks";



export interface ChatState {
  loading: boolean,
  topEndMessages: boolean
  chatRooms: {
    count: number,
    next?: string | null,
    previous?: string | null,
    results: Chats[] | null
  },
  activeRoomChat: Chats | null,
  messages?: Messages,
  activeMessages?: Message[],
  connectionHistory?: ConnectionHistory,
  chatLog: {
    action: string,
    data: any,
    pk?: number
  }[]
}

const initialState: ChatState = {
  loading: false,
  topEndMessages: false,
  chatRooms: {
    count: 0,
    next: null,
    previous: null,
    results: null
  },
  activeRoomChat: null,
  chatLog: [],
  activeMessages: []
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActiveCaht: (state, action: PayloadAction<Chats>) => {
      state.activeRoomChat = action.payload;
    },
    getActiveChat: (state, action: PayloadAction<{ id: string }>) => {
      const activeChat = state.chatRooms?.results?.filter((item: any) => item.worker.id === Number(action.payload.id))
      state.activeRoomChat = activeChat ? activeChat[0] : null
    },
    setChatLog: (state, action: PayloadAction<{ action: string, data: any, pk?: number }>) => {
      if (state.chatLog.length > 20) {
        state.chatLog = [action.payload]
      } else {
        state.chatLog = [...state.chatLog, action.payload]
      }
      if (action.payload.action === 'create') {
        if (state.activeRoomChat?.id === action.payload.data.room.id && state.activeMessages && action.payload.data.id !== state.activeMessages[0].id) {
          state.activeMessages = [action.payload.data, ...state.activeMessages]
        }
        if (state.chatRooms.results) {
          let arr1 = state.chatRooms.results.filter(item => item.id === action.payload.data.room.id)[0]
          let arr2 = state.chatRooms.results.filter(item => item.id !== action.payload.data.room.id)
          state.chatRooms.results = [{ ...arr1, lastMessage: action.payload.data }, ...arr2]
        }
      }
      if (action.payload.action === 'status_change') {
        if (state.connectionHistory && state.connectionHistory.user === action.payload.data.id) {
          if (action.payload.data.status === 'connect') {
            state.connectionHistory = { ...state.connectionHistory, status: 'online' }
          }
          if (action.payload.data.status === 'disconnect') {
            state.connectionHistory = { ...state.connectionHistory, status: 'offline', lastOnline: new Date().toISOString() }
          }
        }
      }
      if (action.payload.action === 'update' && state.activeMessages?.length !== 0) {
        const checkMessage = state.activeMessages?.map(item => {
          if (action.payload.data.id === item.id) {
            return { ...item, status: 'read' }
          }
          return { ...item }
        })
        state.activeMessages = checkMessage
      }
    },
    deleteActiveMessages: (state) => {
      state.activeMessages = []
      state.activeRoomChat = null
      state.topEndMessages = false

    }
  },
  extraReducers: builder => {
    builder.addCase(loadChatRooms.pending, (state) => {
      state.loading = true
    })
    builder.addCase(loadChatRooms.fulfilled, (state, action) => {
      state.loading = false
      state.chatRooms = action.payload
    })
    builder.addCase(createChatRoom.pending, (state) => {
      state.loading = true
    })
    builder.addCase(createChatRoom.fulfilled, (state, action) => {
      state.loading = false
      state.activeRoomChat = action.payload
    })
    builder.addCase(loadMessageByRoom.pending, (state) => {
      state.loading = true
    })
    builder.addCase(loadMessageByRoom.fulfilled, (state, action) => {
      state.messages = action.payload
      state.activeMessages = action.payload?.results
    })
    builder.addCase(loadPrevMessageByRoom.fulfilled, (state, action) => {
      if (action.payload?.results.length === 0) {
        state.topEndMessages = true
      }
      if (state.activeMessages && state.activeMessages?.length > 0)
        state.activeMessages = [...state.activeMessages, ...action.payload?.results]
    })
    builder.addCase(connectionHistoryChat.fulfilled, (state, action) => {
      state.connectionHistory = action.payload
    })
  }
})

export const { setActiveCaht, getActiveChat, setChatLog, deleteActiveMessages } = chatSlice.actions;
export const chatReducer = chatSlice.reducer;

export const selectChatRoomsState = (state: RootState) => state.chat.chatRooms
export const selectActiveChatState = (state: RootState) => state.chat.activeRoomChat
export const selectMessagesState = (state: RootState) => state.chat.activeMessages