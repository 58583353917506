import {useEffect} from "react";

const titleTemplate = (title: string) => `B2C.BY | ${title}`;

export const usePageTitle = (title: string) => {
  useEffect(() => {
    const prevTitle = document.title;

    document.title = titleTemplate(title);

    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}
