import React, { useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { b2cTheme, useGlobalStyles } from "../theme/B2CTheme";
import '../utils/validation';
import { enableMapSet } from "immer";
import { IsMobileContext, useIsMobileContextValue } from '../hooks/useIsMobile';
import store, { useAppDispatch, useAppSelector } from "./store";
import { changeUserToken, selectAuthState, selectUserProfile } from './store/auth/authStore';
import { Provider } from "react-redux";
import { setToken } from "../api";
import { fetchClientProfile } from "./store/auth/profileLoad";
import { AuthWizard } from "../masters/pages/Auth";
import { ClientProfileContext } from "./store/auth/profileContext";
import { BrowserRouter } from "react-router-dom";
import { ClientHeader } from "./components/ClientHeader/ClientHeader";
import { wsUrl } from 'src/config/config';
import { WebSocketContext } from './store/chat/chatContext';
import { WebSocket as WS, SendFunck, SubscribeFunck, ReadFunck, RetrieveFunck } from 'src/entities/WebSocket';
import { setChatLog } from './store/chat/chatStore';

const ClientsDashboardRouter = React.lazy(async () => (await import('./navigation/ClientsDashboardRouter')));

enableMapSet();

const RouterOnAuth = () => {
  const authState = useAppSelector(selectAuthState);
  const clientProfile = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (authState.token) dispatch(fetchClientProfile());
  }, [authState.token, dispatch]);

  if (authState.loading) {
    return <div />;
  }

  let socket: any
  let ws: WS | undefined

  const sendMessage = (data: SendFunck) => {
    socket.send(JSON.stringify(data))
  }
  const subscribeRoom = (data: SubscribeFunck) => {
    socket.send(JSON.stringify(data))
  }
  const statusReadMessage = (data: ReadFunck) => {
    socket.send(JSON.stringify(data))
  }
  const retrieveMessages = (data: RetrieveFunck) => {
    socket.send(JSON.stringify(data))
  }

  if (!socket && authState.token) {
    socket = new WebSocket(`${wsUrl}?token=${authState.token}`)

    socket.onopen = function () {
      console.log('WebSocket is OPEN')
    }

    socket.onmessage = function (event: any) {
      dispatch(setChatLog(JSON.parse(event.data)));
    }

    ws = {
      socket,
      sendMessage,
      subscribeRoom,
      statusReadMessage,
      retrieveMessages
    }
  }

  return (
    <>
      {!!clientProfile && (
        <React.Suspense fallback={<div />}>
          <ClientProfileContext.Provider value={clientProfile}>
            <WebSocketContext.Provider value={ws}>
              <ClientsDashboardRouter />
            </WebSocketContext.Provider>
          </ClientProfileContext.Provider>
        </React.Suspense>
      )}
      {!clientProfile && (
        <div>
          <ClientHeader />
          <React.Suspense fallback={<div />}>
            <AuthWizard
              role={'customer'}
              onUserAuth={(token, profile) => {
                localStorage.setItem('token', token);
                setToken(token);
                dispatch(changeUserToken(token));
                dispatch(fetchClientProfile());
              }}
            />
          </React.Suspense>
        </div>
      )}
    </>
  );
}

interface ClientsDashboardAppProps {
  browserRouterProps?: React.ComponentProps<typeof BrowserRouter>,
}

export const ClientsDashboardApp = (props: ClientsDashboardAppProps) => {
  const isMobileContextValue = useIsMobileContextValue();
  useGlobalStyles();

  return (
    <React.Suspense fallback={<div />}>
      <ThemeProvider theme={b2cTheme}>
        <CssBaseline />
        <Provider store={store}>
          <IsMobileContext.Provider value={isMobileContextValue}>
            <RouterOnAuth />
          </IsMobileContext.Provider>
        </Provider>
      </ThemeProvider>
    </React.Suspense>
  );
}
