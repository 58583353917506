import {Country} from "./Country";
import {Point} from "./Point";
import {EventAddress} from "./Event";
import {City} from "./UserProfile";
import {apiUrlV1} from "../config/config";
import {GoogleAddressObject} from "./GoogleAddressObject";

export type PlaceKind = 'house' | 'street' | 'metro' | 'district' | 'locality';

export interface PlaceType {
  id?: number;
  name: string;
  description: string;
  region?: string;
  cityId?: number;
  pos?: Point;
  country?: Country;
  kind?: PlaceKind;
  active?: boolean;
}

export const fromYandexGeoObject = (geoobject: any): PlaceType => {
  if (!geoobject) {
    throw Error('empty geoobject');
  }

  const geoobjectCountryCode: string = geoobject
    .metaDataProperty
    .GeocoderMetaData
    .Address
    .country_code;

  const components = geoobject
    ?.metaDataProperty
    ?.GeocoderMetaData
    ?.Address
    ?.Components;

  const provinces = components && components?.filter((i: any) => i.kind === "province");
  const province: string = provinces[0]?.name;
  const posStr = geoobject.Point.pos as string;
  const place: PlaceType = {
    description: geoobject.description,
    name: geoobject.name,
    pos: posStr.split(' ').map(Number).reverse() as [number, number],
    country: geoobjectCountryCode as Country,
    region: province,
    kind: geoobject
      ?.metaDataProperty
      ?.GeocoderMetaData?.kind
  };

  return place;
};

export const fromGoogleAddressObject = (obj: GoogleAddressObject, pos: Point, kind: PlaceKind) => {
  const place: PlaceType = {
    description: `${obj.city}, ${obj.region}, ${obj.country}`, //obj.formatted_address,
    name: `${obj.street} ${obj.home}`,
    pos,
    country: obj.country as Country,
    region: obj.region,
    kind,
  };

  return place;
};

export const placeToHomeWorkplace = (place: PlaceType) => {
  return {
    id: place.id,
    name: place.name,
    address: place.description,
    center: {
      type: 'Point',
      coordinates: place.pos || [0, 0]
    },
    isActive: !!place.active,
  };
};

export const placeToEventAddress = (place: PlaceType): EventAddress => {
  return {
    name: place.name,
    address: place.description,
    center: {
      type: 'Point',
      coordinates: place.pos || [0, 0]
    },
  };
};

export const placeToUserProfileCity = (place: PlaceType): City => {
  return {
    id: place.id,
    title: place.name,
    region: place.region || '',
    location: {
      type: 'Point',
      coordinates: place.pos || [0, 0],
    }
  };
};

export const getCityId = (place: PlaceType): Promise<number> => {
  if (place.cityId) {
    return Promise.resolve(place.cityId);
  }

  return fetch(
    `${apiUrlV1}/cities?search=${encodeURIComponent(place.name)}`
  ).then(
    res => res.json()
  ).then(({results: cities}) => {
    const city = cities[0];
    return Number(city?.id);
  });
};

export const locationToPlaceType = (location: any): PlaceType => {
  return {
    ...location,
    pos: location.location.coordinates,
  };
}

export const placeTypeToLocation = (place: PlaceType) => {
  const partPlace = {...place};

  delete partPlace.pos;

  return {
    ...partPlace,
    location: {
      type: "Point",
      coordinates: place.pos
    },
  };
}
