import {makeStyles} from "@material-ui/core";

export const useAuthStyles = makeStyles({
  container: {
    height: '100vh',
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    maxWidth: '680px',
    margin: '0 auto',
    pointerEvents: 'none',

    "& > *": {
      pointerEvents: 'all',
    },
  },
  card: {
    padding: '17px 12px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: "center",
    margin: "0 16px",
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    '& > *': {
      marginTop: 16
    },
    '& > *:first-child': {
      marginTop: 0
    }
  },
  licenseText: {
    textAlign: "center"
  }
});