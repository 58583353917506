import {
  makeStyles, Typography, TextField,
  InputBaseComponentProps
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import React, {useCallback, useEffect, useState} from "react";

const usePhoneInputStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  inputRoot: {
    flex: 1,
    marginLeft: 10
  }
});

interface PhoneInputProps {
  inputProps?: InputBaseComponentProps,
  onPhoneChange: (phone: string) => void,
}

export const PhoneInput = ({inputProps, onPhoneChange}: PhoneInputProps) => {
  const classes = usePhoneInputStyles();
  const [value, valueSet] = useState('');

  const onPaste = useCallback((e: React.ClipboardEvent<HTMLInputElement>) => {
    e.clipboardData.items[0].getAsString(text => {
      const textTrimmed = text.split('').map(s => s.trim()).filter(a => !!a).join('');
      const textCut = textTrimmed.slice(Math.max(textTrimmed.length - 9, 0));
      valueSet(textCut);
    });
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const inProps: InputBaseComponentProps = {
    name: 'tel',
    pattern: "[0-9]*",
    type: "text",
    inputMode: 'numeric',
    onPaste,
    ...inputProps,
  };

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    valueSet(e.target.value);
  }, []);

  useEffect(() => {
    onPhoneChange(value);
  }, [onPhoneChange, value]);

  return (
    <div className={classes.container}>
      <Typography variant="h5">+375</Typography>
      <InputMask
        value={value}
        mask="99 999 99 99"
        onChange={onChange}
      >
        <TextField
          inputProps={inProps}
          classes={{root: classes.inputRoot}}
        />
      </InputMask>
    </div>
  );
}